<template>
  <!-- Layout Header -->
  <a-layout-header class="layout-header">
    <a-row>
      <a-col :span="12">
        <a-button
          class="header-menu-btn"
          type="link" shape="circle"
          @click="appStore.collapsed=!appStore.collapsed"
        >
          <template #icon>
            <span class="mdi mdi-menu" :style="{ color: '#212121', fontSize: '1.5rem' }" />
          </template>
        </a-button>
        
        <div class="header-title">資源庫</div>
      </a-col>
    </a-row>
  </a-layout-header>
  <!-- Layout Header -->

  <a-layout-content class="layout-content">
    <div class="app-materials-page">
      <div class="page__header">
        <a-button type="primary" @click="openCreateMaterialModal">
          <template #icon><PlusOutlined /></template>新增資源夾
        </a-button>
      </div>

      <div class="page__body">
        <template v-if="materialsData.length">
          <a-row :gutter="24">
            <a-col
              v-for="item in materialsData"
              :lg="6" :xs="24"
            >
              <div class="body-card" @click="toMaterialPage(item.id)">
                <div class="card-header">
                  <FolderFilled :style="{ color: '#424242', fontSize: '40px' }" />
                </div>
                <div class="card-body">
                  <div class="body-name">{{ item.name }}</div>
                  <div class="body-description">{{ item.source_count.hw }} 作業 / {{ item.source_count.video }} 影片</div>
                </div>
              </div>
            </a-col>
          </a-row>
        </template>
        
        <template v-else>
          <a-empty
            class="mt-10"
            description="沒有資源夾"
          />
        </template>
      </div>
    </div>
  </a-layout-content>
  
  <CreateMaterialModal
    :visible="createMaterialModalVisible"
    @confirm="handleCreateMaterialModalConfirm"
    @cancel="handleCreateMaterialCancel"
  />
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { UploadOutlined, FileOutlined, EyeOutlined, PlusOutlined, FolderFilled } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import filesize from 'filesize'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import api from '@/apis'
import CreateMaterialModal from './components/CreateMaterialModal.vue'
import FileIcon from '@/components/common/FileIcon.vue'

export default defineComponent({
  components: {
    UploadOutlined,
    FileOutlined,
    EyeOutlined,
    PlusOutlined,
    FolderFilled,
    CreateMaterialModal,
    FileIcon
  },
  setup() {
    const appStore = useAppStore();
    const authStore = useAuthStore();

    /* Data */
    const loading = ref(false);
    const materialsData = ref([]);

    /* Api */
    const getMaterialsData = (async() => {
      materialsData.value = [];
      loading.value = true;
      
      const response = await api.v1.material.list({
        user_id: authStore.info.uid
      });
      materialsData.value = response.data.result;

      console.log(materialsData.value)

      loading.value = false;
    });

    /* Mounted */
    onMounted(async () => {
      await getMaterialsData();
    });
    
    return {
      appStore,

      /* Data */
      loading,
      materialsData,

      /* Api */
      api,
      getMaterialsData,

      /* Utils */
      filesize
    }
  },
  data() {
    return {
      /* Create Material Modal */
      createMaterialModalVisible: false,

      /* Upload File */
      uploading: false,
      uploadingMaterialId: undefined
    }
  },
  methods: {
    /* router */
    openSrc(id) {
      const url = `/api/v1/file/${id}`;
      window.open(url);
    },
    toMaterialPage(id) {
      this.$router.push({
        name: 'AppMaterialPage',
        params: {
          material_id: id
        }
      });
    },

    /* Create Material Modal */
    openCreateMaterialModal() {
      this.createMaterialModalVisible = true;
    },
    handleCreateMaterialModalConfirm() {
      this.createMaterialModalVisible = false;
      this.getMaterialsData();
    },
    handleCreateMaterialCancel() {
      this.createMaterialModalVisible = false;
    },

    /* Upload Files */
    handleClickUploadEvent(id) {
      this.uploadingMaterialId = id;
      this.$refs.file.click();
    },
    async getFiles(event) {
      this.uploading = true;

      const files = event.target.files;
      for (let i=0; i<files.length; i++) {
        const fileSrc = files[i];

        const { code, err } = await this._uploadFile(fileSrc);
        if (err) {
          message.error(err);
          return
        }

        // 新增 material resource
        const result = await this._insertOneResource({
          id: code,
          filename: fileSrc.name,
          mimetype: fileSrc.type,
          size: fileSrc.size
        })
        if (result.err) {
          message.error(result.err);
          return
        }
      }

      this.uploading = false;
      message.success(`成功新增 ${files.length} 項教材資源`);
      this.getMaterialsData();
    },
    async _uploadFile(file) {
      let code = '';
      let err = undefined;

      let formData = new FormData();
      formData.append('file', file, file.name);
      
      try {
        const response = await api.v1.file.insertOne(formData);
        code = response.data.result.code;
      } catch (error) {
        console.log(error);
        err = '檔案上傳失敗，請稍候再試';
      }

      return { code, err }
    },

    /* Api */
    async _insertOneResource(params) {
      let err = undefined;

      try {
        await api.v1.material.insertOneResource(this.uploadingMaterialId, params);
      } catch (error) {
        console.log(error);
        err = '新增教材資源失敗';
      }

      return { err }
    }
  }
})
</script>

<style lang="scss">
.app-materials-page {
  .page__header {
    margin-bottom: 20px;
  }
  
  .page__body {
    .body-card {
      background: white;
      border-radius: 8px;
      padding: 20px;
      cursor: pointer;
      margin-bottom: 16px;

      /* md */
      @media screen and (min-width: 970px) {
        padding: 24px;
      }

      .card-header {
        margin-bottom: 20px;
      }

      .card-body {
        .body-name {
          color: #212121;
          font-size: 1.45rem;
          letter-spacing: 0.025rem;
          margin-bottom: 8px;
        }

        .body-description {
          color: #757575;
        }
      }
    }
  }
}
</style>