<template>
  <template v-if="type.includes('image')">
    <FileImageFilled :style="{ color: '#1976D2' }" />
  </template>

  <template v-if="type.includes('pdf')">
    <FilePdfFilled :style="{ color: '#F44336' }" />
  </template>
</template>

<script>
import { defineComponent } from 'vue'
import { FileImageFilled, FilePdfFilled } from '@ant-design/icons-vue'

export default defineComponent({
  props: ['type'],
  components: {
    FileImageFilled,
    FilePdfFilled
  },
  setup() {
    return {}
  },
  methods: {

  }
})
</script>